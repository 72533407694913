import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import DynamicTable from './DynamicTable';
import './App.css'; 
import ExpandedRow from './ExpandedRow';
import {endpoint} from './mainEndpoint';
import ColumnSettings from './ColumnSettings';
import SettingsDialog from './SettingsDialog';
import {
  TextField, Checkbox, Table,
  TableHead, TableBody, TableRow, TableCell, IconButton,
  TablePagination, Button, Menu, MenuItem, Typography,
  FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { Sort } from '@material-ui/icons';

class Table_All extends Component {

  constructor(props) {
    super(props);

    this.state = {
      table_dir: this.props.table_dir,
      columns: this.props.columns,
      filters: this.props.filters,
      sort: this.props.sort,
      data: [],
      loading: true,
      selectedFolder:this.props.selectedFolder,
      pageNumber:1,
      pageSize: 100,
      folders: this.props.folders,
      searchValue: this.props.searchValue
      // folders:[]
    };
    
  }

  componentDidMount() {
    // const { columns,  filters, sort } = this.state;

    
    Promise.all([this.fetchData()])
      .then(() => {
        console.log('Done loading data and folders');
      })
      .catch((error) => {
        console.error('Error loading data and folders:', error);
      });
  }

  componentDidUpdate(prevProps) {
    // Get all prop names from the props object
    console.log(this.props)
    const propNames = Object.keys(this.props).filter((propName) => propName !== "data");
      const stateNames = Object.keys(this.state);

    // Check if any of the props have changed
    if (propNames.some(propName => prevProps[propName] !== this.props[propName])) {
      this.handleUpdateStateVariables(() => {
        console.log("updating")
        // Callback function to be executed after updating state
        this.setState({data:[]})
        this.fetchData(null,true);
        
        
      });
    } 

    
  }
  
  
  handleUpdateStateVariables = (callback) => {
    // List of prop names to update in the state
    const propNamesToUpdate = Object.keys(this.props);
    let callBack = false;
    console.log(propNamesToUpdate)
    // Keep track of whether any state is updated
    let stateUpdated = false;
  
    // Iterate through prop names and update the state only if the prop has changed
    propNamesToUpdate.forEach(propName => {
      if (this.props[propName] !== undefined && this.props[propName] !== this.state[propName]) {
        this.setState({ [propName]: this.props[propName] }, () => {
          stateUpdated = true;
  
          // Check if all state updates are complete before invoking the callback
          if (propNamesToUpdate.every(name => this.state[name] === this.props[name])) {
            callback = true
            
          }
        });
      }
    });
    if (callback){
      callback();
    }
  
    // If no state is updated, directly invoke the callback
    if (!stateUpdated) {
      // callback();
      // this.fetchData();
    }
  };
  
  fetchData = (stopLoading = null, reloadAllPages = null) => {
    console.log('fetching data');
    const {
      table_dir,
      pageSize,
      filters,
      sort,
      searchValue,
      pageNumber,
      toggleValue,
      selectedFolder,
      data,
    } = this.state;
    // console.log('aaaaaaa')
    if (!stopLoading){
      console.log('aaaaaaa')
      this.setState({loading:true})
    }
    if (reloadAllPages) {
      // If reloading all pages, iterate from page 1 to current page and fetch data
      const fetchPromises = [];
      for (let i = 1; i <= pageNumber; i++) {
        const promise = this.fetchDataForPage(i);
        fetchPromises.push(promise);
      }
  
      Promise.all(fetchPromises)
        .then((allData) => {
          // Concatenate all data from different pages
          const concatenatedData = allData.reduce((acc, pageData) => [...acc, ...pageData], []);
          this.setState({ data: concatenatedData, loading: false });
          console.log('Done loading data for all pages');
          console.log(concatenatedData)
          if (this.props.setData) {
            this.props.setData(concatenatedData);
          }
          if (this.props.setDataComponent) {
            this.props.setDataComponent(this.props.parent_key,this.props.key1, concatenatedData);
          }
        })
        .catch((error) => {
          console.error('Error fetching data for all pages:', error);
        });
    }else {
      // Fetch data for the current page
      return this.fetchDataForPage(pageNumber, stopLoading)
        .then((pageData) => {
          console.log("daaaaaaa",pageData)
          let updatedData =[]
          if (pageNumber === 1){
            updatedData = pageData;
          } else{
            updatedData = [...data, ...pageData];
          }
          this.setState({ data: updatedData, loading: false });
          if (this.props.setData) {
            this.props.setData(updatedData);
          }
          if (this.props.setDataComponent) {
            this.props.setDataComponent(this.props.parent_key,this.props.key1, updatedData);
          }
        })
        .catch((error) => {
          console.error(`Error fetching data for page ${pageNumber}:`, error);
        });
    }
    
  };
  
  fetchDataForPage = (pageToFetch, stopLoading = null) => {
    const {
      table_dir,
      pageSize,
      
      searchValue,
      toggleValue,
      selectedFolder,
    } = this.state;
    const {
      filters,
      sort,
    } = this.props
  
    const sortType = sort.type;
    const sortOrder = sort.order;
    const sortColumn = sort.key;
  
    const queryParams = new URLSearchParams();
  
    if (filters) {
      queryParams.append('filters', JSON.stringify(filters));
    }
    const columnKeyToUpdate = 'FOLDER_ID';

    let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
    if (existingColumn) {
      let selectedFolder = existingColumn.value
      queryParams.append('folderId', selectedFolder);
    }
    if (sortColumn && sortOrder) {
      queryParams.append('sortColumn', sortColumn);
      queryParams.append('sortOrder', sortOrder);
      queryParams.append('sortType', sortType);
    }
  
    if (searchValue) {
      queryParams.append('searchValue', searchValue);
    }
  
    if (toggleValue) {
      queryParams.append('toggleValue', toggleValue);
    }
  
    queryParams.append('pageNumber', pageToFetch);
    queryParams.append('pageSize', pageSize);
  
    
  
    const queryString = queryParams.toString();
    const url = `${endpoint}/${table_dir}?${queryString}`;
  
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    if (!stopLoading) {
      this.setState({ loading: true });
    }
  
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: headers,
      })
        .then((response) => response.json())
        .then((newData) => {
          this.setState({ loading: false });
          console.log(`Done loading data for page ${pageToFetch}`);
          resolve(newData);
        })
        .catch((error) => {
          console.error(`Error fetching data for page ${pageToFetch}:`, error);
          reject(error);
        });
    });
  };
  
  
  onLoadMore = async () => {
    console.log('aa')
    if (!this.props.dontLoadMore) {
      const { pageNumber } = this.state;
      console.log(this.state.loading)
      if (this.state.loading){
        return;
      }
      if (!(await this.hasMoreData(pageNumber + 1))) {
        console.log('')
        return;
      }
      // Increment the page number and fetch more data
      this.setState({ pageNumber: pageNumber + 1, loading: true }, () =>
        this.fetchData().then(() => this.setState({ loading: false }))
      );
    }
    
  };
  setLoading = () => {
    this.setState({loading:true})
    return 0;
  }

  hasMoreData = async (pageToCheck) => {
    const {
      table_dir,
      pageSize,
      filters,
      sort,
      searchValue,
      toggleValue,
      selectedFolder,
    } = this.state;
  
    const sortType = sort.type;
    const sortOrder = sort.order;
    const sortColumn = sort.key;
  
    const queryParams = new URLSearchParams();
  
    if (filters) {
      queryParams.append('filters', JSON.stringify(filters));
    }
  
    if (sortColumn && sortOrder) {
      queryParams.append('sortColumn', sortColumn);
      queryParams.append('sortOrder', sortOrder);
      queryParams.append('sortType', sortType);
    }
  
    if (searchValue) {
      queryParams.append('searchValue', searchValue);
    }
  
    if (toggleValue) {
      queryParams.append('toggleValue', toggleValue);
    }
  
    queryParams.append('pageNumber', pageToCheck);
    queryParams.append('pageSize', pageSize);
  
    queryParams.append('folderId', selectedFolder);
  
    const queryString = queryParams.toString();
    const url = `${endpoint}/${table_dir}?${queryString}`;
  
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    try {
      const response = await fetch(url, {
        headers: headers,
      });
  
      const newData = await response.json();
  
      // Check if there is more data based on your specific logic
      return newData.length > 0; // Adjust this condition based on your data-fetching logic
    } catch (error) {
      console.error(`Error checking if there is more data for page ${pageToCheck}:`, error);
      return false; // Assume there is no more data in case of an error
    }
  };
  
  
  
  handleMainFilterChange = (filters) => {
    this.setState({
      filters
    });
  };
  handleMainColumnChange = (columns) => {
    this.setState({
      columns
    });
  };
  
  handleSort = (column) => {
    console.log('hi');
    console.log(column);

    const { sort } = this.state;
    this.setState(
      {
        data:[],
        loading: true
      })
    console.log(sort)
    let newSortOrder = 'desc';

    if (sort.key === column.key) {
      newSortOrder = sort.order === 'desc' ? 'asc' : 'desc';
    }
    console.log(newSortOrder);
    let newSort = { key: column.key, type: column.type, order: newSortOrder }
    this.setState(
      {
        sort: newSort,
        pageNumber: 1,
      },
      () => {
        // Code to run after the state has been updated
        this.fetchData()
        if (this.props.setSort){
          this.props.setSort(newSort)
        }
      }
    );
    
  };
  

  // fetchFolders() {
  //   // const { endpoint } = this.props;
  //   console.log('fetching folders');
  //   const url = `${endpoint}/folders`;
  //   const { authState } = this.props;
  //   const accessToken = authState.accessToken.accessToken;
  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`
  //   };

  //   // Simulating data fetching
  //   return new Promise((resolve, reject) => {
  //     fetch(url,
  //       {
  //         headers: headers // Add the headers to the fetch options
  //       })
  //       .then((response) => response.json())
  //       .then((folders) => {
  //         this.setState({ folders, loadingFolders: false });
  //         console.log('Done loading folders');
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching folders:', error);
  //       });
  //   });
  // }
  handleFilterChange = (event, columnName, columnType, operator, value =null, optionsMatchingKey= null, popupState = null) => {
    // const {handleMainFilterChange} = this.props
    const { filters } = this.state;
    console.log(value)
    console.log(operator)
    let filterValue = null
    if (operator === 'search' && !value) {
      filterValue =''
    } else {
      filterValue = value ? value : event.target.value;
    }
    this.setState(
      {
        data:[],
        loading: true
      })
    console.log(value)
    let existingFilter = filters.find((filter) => filter.column === columnName &&  filter.operator !== 'weight');
    const updatedFilters = filters.filter((filter) => (filter.column === columnName && filter.operator === 'weight') || (filter.column !== columnName) );
    let dontPush = false;
    if (filterValue !== '') {
      let filter = {
        column: columnName,
        operator: '=',
        value: filterValue
      };

      if (columnType === 'number') {
        // filter.operator = operator;
        dontPush = true
        Object.keys(filterValue).forEach((operator) => {
          const inputValue = filterValue[operator];
          const intValue = parseInt(inputValue, 10);
          console.log(inputValue)
          console.log(intValue)
          console.log(operator)
          if (inputValue !== '') {
            filter = {
              column: columnName,
              operator: operator,
              value: inputValue
            }
            updatedFilters.push(filter)
          }
          
        })
        


      } else if (columnType === 'date') {
        if (operator === 'past week') {
          const inputFields = document.querySelectorAll(`input[type="date"]`);
          inputFields.forEach((input) => {
            input.value = '';
            // console.log(input)
          });
          const pastWeek = new Date();
          pastWeek.setDate(pastWeek.getDate() - 8);
          const today = new Date();
          filter.operator = '>';
          filter.value = pastWeek.toISOString().split('T')[0];
          updatedFilters.push(filter);

          filter = {
            column: columnName,
            operator: '<=',
            value: today.toISOString().split('T')[0]
          };
        } else if (operator === 'past month') {
          const inputFields = document.querySelectorAll(`input[type="date"]`);
          inputFields.forEach((input) => {
            input.value = '';
            // console.log(input)
          });
          const pastMonth = new Date();
          pastMonth.setMonth(pastMonth.getMonth() - 1);
          const today = new Date();
          filter.operator = '>';
          filter.value = pastMonth.toISOString().split('T')[0];
          updatedFilters.push(filter);

          filter = {
            column: columnName,
            operator: '<=',
            value: today.toISOString().split('T')[0]
          };
        } else {
          filter.operator = operator;
          if (existingFilter) {
            if ((operator === '>') && (existingFilter.operator === '<')) {
              updatedFilters.push(existingFilter)
            } else if ((operator === '<') && (existingFilter.operator === '>')) {
              updatedFilters.push(existingFilter)
            }
          }
        }
      } else if (columnType === 'category') {
        if (filterValue.length !== 0) {
          filter = {
            column: columnName,
            operator: 'in',
            value: filterValue,
            optionsMatchingKey: optionsMatchingKey,
          };
        } else{
          dontPush = true
        }
        
      }
      else if (columnType === 'string') {
        if (filterValue !== '') {
          filter = {
            column: columnName,
            operator: 'search',
            value: filterValue
          }}
          else{
            dontPush = true
          }
      }
      console.log(filter)
      if (!dontPush){
        updatedFilters.push(filter);
      }
      
    }
    // handleMainFilterChange(updatedFilters)
    this.setState(
      {
        filters: updatedFilters,
        pageNumber: 1,
      },
      () => {
        // Code to run after the state has been updated
        this.fetchData()
        if (this.props.setFilters){
          this.props.setFilters(updatedFilters)
        }
      }
    );
    if (popupState){
      popupState.close()
    }
    
    
  };

  render() {
    // const endpoint = 'http://localhost:3500'; // Replace with your desired endpoint
    const { table_dir, columns, filters, data, sort, loading,folders } = this.state;
console.log(loading)

return (
  <div style={{marginLeft:'7px'}}>
    
    <DynamicTable
        columns={columns}
        table_dir={table_dir}
        filters={filters}
        data={data}
        sort={sort}
        loading={loading}
        handleFilterChange={this.handleFilterChange}
        handleSort={this.handleSort}
        ExpandedRowComp={this.props.ExpandedRow}
        onLoadMore={this.onLoadMore}
        folders={folders}
        fetchData={this.fetchData}
        setLoading={this.setLoading}
        folderType={this.props.folderType}
      />
    {/* {loading ? (
      <p>Loading...</p>
    ) : (
      <DynamicTable
        columns={columns}
        table_dir={table_dir}
        filters={filters}
        data={data}
        sort={sort}
        loading={loading}
        handleFilterChange={this.handleFilterChange}
        handleSort={this.handleSort}
        ExpandedRowComp={ExpandedRow}
      />
    )} */}
  </div>
);

  }
}

export default withOktaAuth(Table_All);


