import React, { Component } from 'react';
import Highstock from 'highcharts/highstock'; // Import Highstock
import HighchartsReact from 'highcharts-react-official';
import { Paper, Typography } from '@material-ui/core';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
import 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import exporting from 'highcharts/modules/exporting';

import { Highcharts } from 'highcharts/highcharts';
// import { exporting } from 'highcharts/exporting';
const styles = {
    graphContainer: {
        maxWidth: '100%',
        width: '100%',
        margin: '0 auto',
    },
};
HighchartsExporting(Highstock);
// exporting(Highstock);

require('highcharts/modules/exporting')(Highstock);
require('highcharts/modules/export-data')(Highstock);
require('highcharts/modules/annotations')(Highstock);

class LinePlotSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data: [],
        loading: true,
        startDate: null, // To store the start date
        seriesVisibility: {},
        exportedChartImage: null
        }
        this.chartRef = React.createRef();

    };


    componentDidMount() {
        this.fetchData();
    }
    // componentDidUpdate(prevProps, prevState) {
        
    //     if (this.chartRef.current && this.state.data !== prevState.data) {
    //       // Chart is initialized, call handleChartExport
    //       if (this.props.setDataComponent) {
    //       this.handleChartExport()
    //     }
    //     }
    //   }

    fetchData() {
        const { sub_endpoint, xKeyName, yKeyNames, properties} = this.props;
        const { artistId } = this.props;
        const { authState } = this.props;
        const accessToken = authState.accessToken.accessToken;
        const queryParams = new URLSearchParams();
        // queryParams.append('PERIOD', 'daily');
        
        // queryParams.append('artistId', artistId);
        console.log(properties)
        for (const key in properties) {
            if (properties.hasOwnProperty(key)) {
              const value = properties[key];
              queryParams.append(key, value);
            }
          }
        const queryString = queryParams.toString();
        // console.log(yKeyNames)

        fetch(`${endpoint}/${sub_endpoint}?${queryString}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const dates = data.map((item) => new Date(item[xKeyName])); // Convert dates to Date objects
                const minDate = new Date(Math.min(...dates)); // Calculate the minimum date
                const seriesVisibility = Object.keys(yKeyNames).reduce((visibility, key) => {
                    visibility[key] = !yKeyNames[key].defaultNotVisible;
                    return visibility;
                }, {});
                // console.log(seriesVisibility)
                // console.log(minDate)
                this.setState({
                    data,
                    loading: false,
                    seriesVisibility: seriesVisibility,
                    startDate: minDate, // Set the default start date
                });
                if (this.props.setDataComponent){
                    this.props.setDataComponent(this.props.parent_key,this.props.key1, data)
                    // this.handleChartExport()
                }
                
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                this.setState({ loading: false });
            });
    }

    handleChartExport = () => {
        console.log('saving chart png');
        const chart = this.chartRef.current.chart;
      
        if (chart) {
          // Get the chart image data
          const imageData = chart.getImage();
      
          // Update the state with the exported chart image data
          this.props.setDataComponent(
            this.props.parent_key,
            this.props.key1,
            imageData,
            true
          );
        }
      };
      

    handleDateRangeChange = (selectedDateRange) => {
        this.setState({ selectedDateRange });
    };

    handleSeriesVisibilityChange = (yKey, isVisible) => {
        // console.log(yKey)
        // console.log(isVisible)
        // console.log(this.state.seriesVisibility)
        this.setState((prevState) => ({
            seriesVisibility: {
                ...prevState.seriesVisibility,
                [yKey]: !isVisible,
            },
        }));
    };
    handleLegendItemClick(event) {
        const seriesName = event.target.name
        const isVisible = event.target.visible;
        const yKeyName = Object.keys(this.props.yKeyNames).find(key => this.props.yKeyNames[key].displayName === seriesName);

        this.handleSeriesVisibilityChange(yKeyName, isVisible);
    }

    
    
    
    
    render() {
        const { data, loading} = this.state;
        const { yKeyNames } = this.props;
        // console.log(yKeyNames)
        let series = []
        // console.log(this.props.pct_change)
        if (!this.props.pct_change) {
            series = Object.keys(yKeyNames).map((yKey) => ({
                name: yKeyNames[yKey].displayName,
                data: data.map((item) => [
                    new Date(item[this.props.xKeyName]).getTime(),
                    item[yKey],
                ]),
                color: yKeyNames[yKey].color,
                visible: this.state.seriesVisibility[yKey],
                showInNavigator: true,
            }));
        }
        if (this.props.left_right) {
            series = Object.keys(yKeyNames).map((yKey) => ({
                name: yKeyNames[yKey].displayName,
                data: data.map((item) => [
                    new Date(item[this.props.xKeyName]).getTime(),
                    item[yKey],
                ]),
                color: yKeyNames[yKey].color,
                visible: this.state.seriesVisibility[yKey],
                showInNavigator: true,
                yAxis: yKeyNames[yKey].yAxis
            }));
        }

        const startDate = this.state.startDate;
        // console.log(startDate)
        if (this.props.pct_change) {
            series = Object.keys(yKeyNames).map((yKey) => {
                let pctChange =0
                let lastNonNully = 0
                const seriesData = data.map((item, index) => {
                    const xValue = new Date(item[this.props.xKeyName]).getTime();
                    const yValue = item[yKey];
                    
                    if ((this.props.pct_change) && startDate) {
                        let startIndex = data.findIndex((d) => new Date(d[this.props.xKeyName]) >= startDate);
                        let startValue = 0
                        try {
                            startValue = data[startIndex][yKey];
                        } catch {
                            const dates = data.map((item) => new Date(item[this.props.xKeyName])); // Convert dates to Date objects
                            const minDate = new Date(Math.min(...dates)); 
                            startIndex = data.findIndex((d) => new Date(d[this.props.xKeyName]) >= minDate);
                            startValue = data[startIndex][yKey];
                        }
                        
            
                        if (startValue !== 0 && yValue !== null && yValue !== 0) {
                            pctChange = (((yValue - startValue) / startValue) * 100).toFixed(1);
                            lastNonNully = yValue;
                        }
                    }
            
                    return { x: xValue, y: parseFloat(pctChange), value: lastNonNully };
            
                });
            
                // Define the series object
                const seriesObj = {
                    id: yKey, // Set the 'id' property for each series
                    name: yKeyNames[yKey].displayName,
                    data: seriesData,
                    color: yKeyNames[yKey].color,
                    visible: this.state.seriesVisibility[yKey],
                    showInNavigator: true,
                    type: 'line', // Always set it to 'line'
                };
            
                // If pct_change is true, disable markers (dots)
                if (this.props.pct_change) {
                    seriesObj.marker = { enabled: false };
                }
            
                return seriesObj;
            })
        }

        const tooltip = {
            shared: true,
        };
        
        if (this.props.pct_change) {
            tooltip.pointFormatter = function () {
                const seriesName = this.series.name;
                const yValue = this.value;
                const xValue = new Date(this.x).toLocaleDateString(); // Format the date
                const pctChange = (this.y !== undefined) ? `(${this.y}%` : '';
                return `<span style="color:${this.color}">\u25CF</span> ${seriesName}: <b>${yValue}</b> ${pctChange})<br>`;
            };
        }

        const config = {
            title: {
                text: this.props.title,
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%b %e}',
                },
                title: {
                    //   text: this.props.xAxisTitle,
                    text: ''
                },
                zoomEnabled: false,
                events: {
                    setExtremes: (e) => {
                        const newStartDate = new Date(e.min);
                        this.setState({ startDate: newStartDate });
                    },
                },
            },
            yAxis: [{
                title: {
                    text: '', // Y-axis title
                },
                labels: {
                    formatter: function () {
                        if (this.value >= 1000 && this.value < 1000000) {
                            return (this.value / 1000).toFixed(0) + 'K';
                        } else if (this.value >= 1000000 && this.value < 1000000000) {
                            return (this.value / 1000000).toFixed(0) + 'M';
                        } else if (this.value >= 1000000000) {
                            return (this.value / 1000000000).toFixed(0) + 'B';
                        } else {
                            return this.value;
                        }
                    },
                },
                zoomEnabled: false,
            },
            {
                title: {
                    text: '', // Y-axis title
                },
                labels: {
                    formatter: function () {
                        if (this.value >= 1000 && this.value < 1000000) {
                            return (this.value / 1000).toFixed(0) + 'K';
                        } else if (this.value >= 1000000 && this.value < 1000000000) {
                            return (this.value / 1000000).toFixed(0) + 'M';
                        } else if (this.value >= 1000000000) {
                            return (this.value / 1000000000).toFixed(0) + 'B';
                        } else {
                            return this.value;
                        }
                    },
                },
                zoomEnabled: false,
                opposite: true,
            },
        
            ],
            // tooltip: {
            //     shared: true,
                
                
            // },
            tooltip,
            rangeSelector: {
                enabled:true
            },
            series: series,
            chart: {
                height: 370,
                backgroundColor: this.props.backgroundColor,
                // borderWidth: 10, // Remove the border
                // borderRadius: 10,
                // borderColor: '#F0F0F0',
                shadow: false,
                
                
            },
            navigator: {
                enabled: true,
                seriesData: series.map((s) => ({
                    data: s.data,
                    type: 'line',
                    name: s.name,
                })),
            },
            plotOptions: {
                series: {
                    // allowPointSelect: true,
                    marker: {
                        enabled: true,
                    },
                    events: {
                        legendItemClick: function (event) {
                            this.handleLegendItemClick(event);
                        }.bind(this),
                    },
                },
            },
            legend: {
                enabled: !this.props.hideLegend, // Hide the legend
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        menuItems: ['downloadCSV', 'downloadPNG'],
                        csv: {
                            dateFormat: '%d/%m/%Y'
                        }
                    },
                }
            }

            
            
        };


        return (
            
            <div style={styles.graphContainer}>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <>

                        <HighchartsReact highcharts={Highstock} options={config} 
                        handleSeriesVisibilityChange={this.handleSeriesVisibilityChange}
                        ref={this.chartRef}
                        
                        />
                    </>
                    
                )}
                
            </div>
        );
    }
}

export default withOktaAuth(LinePlotSimple);
