import React, { Component } from 'react';
import { Button, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ReactDOMServer from 'react-dom/server';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';

class ExportData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_dir: this.props.table_dir,
            columns: this.props.columns,
            filters: this.props.filters,
            table_endpoint:this.props.table_endpoint,
            selectedFolder:this.props.selectedFolder? this.props.selectedFolder : -1,
            sort: this.props.sort,
            // isOptionsDialogOpen: false,
            isEmailPreviewOpen: false,
            emailHtml: '',
            buildingWeekOption: 'Building Week',
            emailAddresses: '',
        };
    }

    formatValue = (value, type, format) => {
        if (value === null) {
          return '';
        }
    
        if (type === 'number') {
          if (format === 'literal') {
            return value || ''; // If value is undefined, return an empty string
          } else if (format === 'percentChange') {
            const roundedValue = Math.round(value * 10) / 10;
            return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
          } else if (format === 'number') {
            const absValue = Math.abs(value);
            if (absValue >= 1e9) {
              return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
            } else if (absValue >= 1e6) {
              return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
            } else if (absValue >= 1e3) {
              return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
            } else {
              return value || ''; // If value is undefined, return an empty string
            }
          }
        } else if (type === 'date') {
          const formattedDate = new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          return formattedDate;
        }
        return value;
      };

    generateCSVContent = (data) => {
        const { columns } = this.props;
        const headerRow = columns.map((column) => column.displayName);
    
        const formattedData = data.map((row) =>
          columns.map((column) => {
            const formattedValue = this.formatValue(row[column.key], column.type, column.format);
            if (column.type === 'number' || column.type === 'percentChange') {
              return row[column.key]
            }
            return (column.type === 'string' || column.type === 'date') ? `"${formattedValue.replace(/"/g, '""')}"` : formattedValue;
    
          })
        );
    
        const csvRows = [headerRow, ...formattedData];
        const csvContent = csvRows.map((row) => row.join(',')).join('\n');
        return csvContent;
      };

    exportDataToCSV = () => {
        // Perform the data export request with the updated filters
        const {data} = this.props
        
        // Generate the CSV content
        const csvContent = this.generateCSVContent(data);

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        downloadLink.setAttribute('download', 'data.csv');
        downloadLink.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadLink.href);
            
    };


    // openOptionsDialog = () => {
    //     this.setState({ isOptionsDialogOpen: true });
    // }

    // closeOptionsDialog = () => {
    //     this.setState({ isOptionsDialogOpen: false });
    // }

    openEmailPreview = () => {
        // this.generateEmailHtml();
        this.setState({
            isEmailPreviewOpen: true,
            isOptionsDialogOpen: false, // Close the options dialog when opening email preview
        });
    }

    closeEmailPreview = () => {
        this.setState({ isEmailPreviewOpen: false });
    };

    selectOption = (option) => {
        this.setState({ buildingWeekOption: option });
      }

    
    getArrowSymbol(change) {
        return change > 0 ? <span style={{ color: 'green' }}>&#8599;</span> : change < 0 ? <span style={{ color: 'red' }}>&#8600;</span> : '';
    }

    handleEmailAddressesChange = (event) => {
        this.setState({
            emailAddresses: event.target.value,
        });
    };
    sendEmail = async () => {
        const { emailHtml } = this.state;
    
        // Get the input value from the ref
        const emailAddresses = this.emailInput.value;
        const emailTitle= this.emailTitle.value;
        
        const { authState, data, columns, filters,sort, table_endpoint} = this.props;
        var filters_str = JSON.stringify(filters)
        const accessToken = authState.accessToken.accessToken;
        try {
          const response = await fetch(`${endpoint}/send_artist_table_email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
            },
            body: JSON.stringify({
              emailHtml,
              emailAddresses,
              emailTitle,
              sort,
              columns,
              filters_str,
              table_endpoint,
            }),
          });
    
          if (response.ok) {
            console.log('Email sent successfully');
            this.setState({ isEmailPreviewOpen: false }); // Close the email preview popup
            // No need to reset the emailAddresses state here
            this.setState({ isEmailSent: true }); // Set the flag to show the "Email Sent!" popup
          } else {
            console.error('Error sending email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
    render() {
        const { isOptionsDialogOpen, isEmailPreviewOpen, emailHtml, buildingWeekOption } = this.state;

        return (
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant="outlined" color="primary" {...bindTrigger(popupState)} style={{  }}>
                            Export
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.exportDataToCSV} variant="outlined" color="primary">
                                    CSV
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.openEmailPreview} variant="outlined" color="primary">
                                    Email
                                </Button>
                            </MenuItem>
                        </Menu>
                        
                        <Dialog
                open={isEmailPreviewOpen}
                onClose={this.closeEmailPreview}
                maxWidth="lg" // Set the maxWidth property to limit the width
              >
                <DialogTitle style={{ width: '80vw' }}>Email Preview</DialogTitle>
                <DialogContent>
                  <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                </DialogContent>
                <DialogActions>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Title:
                    </Typography>
                    <TextField
                      label="Title"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter Email Title"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailTitle = input)} // Create a ref for the input
                    />
                  
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Send To:
                    </Typography>
                    <TextField
                      label="Emails"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter email addresses, comma-separated"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailInput = input)} // Create a ref for the input
                    />
                  </div>
                  <Button onClick={this.sendEmail}>Send Email</Button>
                </DialogActions>
              </Dialog>
                    </React.Fragment>
                )}
            </PopupState>
        );
    }
}

export default withOktaAuth(ExportData);
