import React, { Component } from 'react';
import SettingsIcon from '@material-ui/icons/Settings'; // Import the settings icon
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DynamicTable from './DynamicTable';
import ExpandedRow from './ExpandedRow';
import { endpoint } from './mainEndpoint';
import ColumnSettings from './ColumnSettings';
import { withOktaAuth } from '@okta/okta-react';


class ScheduleEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      columnHideSettings: this.props.columns,
      showAllColumns: true,
      hour: '',
      minute: '',
      dayOfWeek: '', // Reference for day of week number
      recipients: '',
      isScheduledSuccessfully: false, // State to manage the dialog visibility
      schedules: [] // State to store the list of schedules
    };
  }

  componentDidMount() {
    this.fetchSchedules(); // Fetch schedules when component mounts
  }

  fetchSchedules = async () => {
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/get_user_schedules`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          }
      });
  
      if (response.ok) {
        const schedules = await response.json();
        this.setState({ schedules });
      } else {
        console.error('Error fetching schedules');
      }
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };


  handleOpenDialog = () => {
    this.fetchSchedules(); // Fetch schedules every time the dialog is opened
    this.setState({ isOpen: true });
  };
  

  handleCloseDialog = () => {
    this.setState({ isOpen: false });
  };

  handleSuccessDialogClose = () => {
    // Close the success dialog and all other dialogs
    this.setState({ isOpen: false, isScheduledSuccessfully: false });
  };

  handleSubmit = async () => {
    const { hour, minute, dayOfWeek, recipients } = this.state;
    const { selectedFilterColumn } = this.props;
    const { authState, data, columns, filters,sort} = this.props;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/schedule_artist_email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
        body: JSON.stringify({
          hour,
          minute,
          dayOfWeek,
          recipients,
          selectedFilterColumn,
        }),
      });
  
      if (response.ok) {
        console.log('Schedule email request sent successfully');
        // Show success dialog
        this.setState({ isScheduledSuccessfully: true });
      } else {
        console.error('Error sending schedule email request');
        // Optionally, you can handle error feedback here
      }
    } catch (error) {
      console.error('Error sending schedule email request:', error);
      // Optionally, you can handle error feedback here
    }
  };
  handleDelete = async (id) => {
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/delete_email_schedule`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
        body: JSON.stringify({
            id,
          }), 
      });
  
      if (response.ok) {
        console.log('Email schedule deleted successfully');
        // Fetch schedules again after deletion
        this.fetchSchedules();
      } else {
        console.error('Error deleting email schedule');
      }
    } catch (error) {
      console.error('Error deleting email schedule:', error);
    }
  };


  render() {
    const { selectedFilterColumn } = this.props;
    const { isScheduledSuccessfully } = this.state;
    const { schedules } = this.state;
    console.log(schedules)
    return (
      <div>
        <Button onClick={this.handleOpenDialog} style={{ cursor: 'pointer' }} variant="outlined" color="primary">
          Schedule
        </Button>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseDialog} maxWidth="lg">
          <DialogTitle style={{ width: '80vw' }}>Schedule</DialogTitle>
          <DialogContent>
            {selectedFilterColumn === -1 ? (
              <p>Please select a saved columns / filters</p>
            ) : (
              <>
                <p>Create a new schedule for the selected saved columns / filters (Use Eastern Standard Time)</p>
                <p>Hour 0 - 23, Minute 0 - 59</p>
  
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '10px' }}>
                    <TextField
                      label="Hour"
                      type="number"
                      value={this.state.hour}
                      onChange={(e) => this.setState({ hour: parseInt(e.target.value) })}
                      inputProps={{ min: 0, max: 23 }}
                    />
                  </div>
                  <div style={{ marginRight: '20px' }}>
                    <TextField
                      label="Minute"
                      type="number"
                      value={this.state.minute}
                      onChange={(e) => this.setState({ minute: parseInt(e.target.value) })}
                      inputProps={{ min: 0, max: 59 }}
                    />
                  </div>
                  <div style={{ marginRight: '10px', marginTop: 'auto' }}>
                    <Select
                      value={this.state.dayOfWeek}
                      onChange={(e) => this.setState({ dayOfWeek: e.target.value })}
                      displayEmpty
                      style={{ marginTop: 'auto' }}
                    >
                      <MenuItem value="" disabled>
                        Select Day of Week
                      </MenuItem>
                      <MenuItem value={6}>Sunday</MenuItem>
                      <MenuItem value={0}>Monday</MenuItem>
                      <MenuItem value={1}>Tuesday</MenuItem>
                      <MenuItem value={2}>Wednesday</MenuItem>
                      <MenuItem value={3}>Thursday</MenuItem>
                      <MenuItem value={4}>Friday</MenuItem>
                      <MenuItem value={5}>Saturday</MenuItem>
                    </Select>
                  </div>
                  <div style={{ marginRight: '10px', width :'300px' }}>
                    <TextField
                      label='Recipients'
                      fullWidth
                      placeholder="Enter email addresses, comma-separated"
                      style={{ flex: '1' }}
                      value={this.state.recipients}
                      onChange={(e) => this.setState({ recipients: e.target.value })}
                    />
                  </div>
                  <div>
                    <Button onClick={this.handleSubmit} color="primary">Submit</Button>
                  </div>
                </div>
              </>
            )}
            <h3>Current Scheduled Emails</h3>
            {schedules.map((schedule, index) => (
              <div key={index}>
                <p>Selected Filters/Columns: {schedule.NAME}</p>
                <p>Hour: {schedule.HOUR}</p>
                <p>Minute: {schedule.MINUTE}</p>
                <p>Day of Week: {schedule.DAY_OF_WEEK}</p>
                <p>Recipients: {schedule.RECIPIENTS}</p>
                <Button onClick={() => this.handleDelete(schedule.ID)} style={{ backgroundColor: 'red', color: 'white' }}>Delete</Button>

                </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={isScheduledSuccessfully} onClose={this.handleSuccessDialogClose}>
          <DialogTitle>Report scheduled successfully</DialogTitle>
          <DialogContent>
            <p>Your report has been scheduled successfully.</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSuccessDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withOktaAuth(ScheduleEmail);
