import React, { Component } from 'react';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import LinePlot from './LinePlot';
import ScatterPlot from './ScatterPlot';
import LinePlotSimple from './LinePlotSimple'
import Toggle from './Toggle'; // Import your toggle component
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: '0px', // Add your custom styles here
  },
}));
class ExpandedRow extends Component {
  state = {
    activeTab: 0,
    isMobile: window.innerWidth <= 768,
    selectedPeriod: 'daily',
    selectedDsp: 'all'
  };

  handlePeriodChange = (period) => {
    this.setState({ selectedPeriod: period });
  }
  handleDspChange = (period) => {
    this.setState({ selectedDsp: period });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  render() {
    const { selectedDsp, selectedPeriod,activeTab,isMobile } = this.state;
    const { endpoint,row, id_column} = this.props;
    let chart_yKeyNames = {}
    if (this.state.selectedDsp === 'all') {
      chart_yKeyNames = {
          MRC_STREAMS: {
              displayName: 'MRC Streams',
              color: '#5C73A9',
              yAxis:0,
              
          },
          INTERNAL_STREAMS: {
              displayName: 'Internal Streams',
              color: '#7cb5ec',
              yAxis:0,
              defaultNotVisible: true
          },
          ORGANIC_STREAMS: {
            displayName: 'Organic Streams',
            color: '#f7a35c',
            yAxis:0,
            defaultNotVisible: true
          },
          PASSION_SCORE: {
            displayName: 'Passion Score',
            color: '#FF5733',
            yAxis:1,
            defaultNotVisible: true
          },
          SKIP_RATE: {
            displayName: 'Skip Rate',
            color: '#90ee7e',
            yAxis:1,
            defaultNotVisible: true
          },
          TIKTOK_CREATES: {
            displayName: 'TikTok Creates',
            color: '#A0DADF',
            yAxis:1,
            defaultNotVisible: true
          }
      }
    } else {
      chart_yKeyNames = {
        APPLE: {
          displayName: 'Apple',
          color: '#E74C3C',
          yAxis:0,
        },
        SPOTIFY: {
            displayName: 'Spotify',
            color: '#07BC0C',
            yAxis:0,
        },
        AMAZON: {
          displayName: 'Amazon',
          color: '#FF9B0C',
          yAxis:0,
        },
        PANDORA: {
          displayName:'Pandora',
          color: '#224099',
          yAxis:0,
          defaultNotVisible: true,
        },
        SOUNDCLOUD: {
          displayName: 'Soundcloud',
          color: '#FF5500',
          yAxis:0,
          defaultNotVisible: true,
        },
        AUDIOMACK: {
          displayName: 'Audiomack',
          color: '#FFA61A',
          yAxis:0,
          defaultNotVisible: true
        },
        DEEZER: {
          displayName: 'Deezer',
          color: '#FD34B4',
          yAxis:0,
          defaultNotVisible: true
        },
        Other: {
          displayName: 'Other',
          color: '#CCCCCC',
          yAxis:0,
          defaultNotVisible: true
        },
     }
    }
    console.log(row)
    const info = {
      // artistId: artistId,
        properties: {
          UNIFIED_SONG_ID: row['UNIFIED_SONG_ID'],
          FAMILY_ID: row['FAMILY_ID'],
          PERIOD:this.state.selectedPeriod,
          DSP: this.state.selectedDsp,
          REGION:'us'
      },
      chart_endpoint: 'daily_stream_stats_historical',
      value_format: 'number',
      chart_xKeyName: "DATE",
      chart_title: "Streams",
      chart_xAxisTitle: 'Date',
      chart_yAxisTitle: 'Values',
      // hideLegend: true,
      chart_pct: false,
      left_right:true,
      chart_yKeyNames: chart_yKeyNames
    }
    

    return (
      <div style={{ width: '100%', display: isMobile ? 'block' : 'flex' }}>   
        <Tabs value={activeTab} onChange={this.handleTabChange}  orientation={isMobile ? 'horizontal' : 'vertical'}
>
        <Tab label="Stream Stats" />
          {/* <Tab label="Stream Details" /> */}
          {/* <Tab label="Benchmark" /> */}
        </Tabs>

        {/* Content */}
        <div style={{ width: '100%' }}>
        <TabPanel
          disableGutters
          style={{ padding: '0px !important' }}
          sx={{ padding: 0 }}
          value={activeTab}
          classes={{
            root: {
              '& .MuiBox-root': {
                padding: '0px !important',
              },
            },
          }}
          index={0}
        >

          
        <div style ={{
                width:'100%',
                position: 'relative',
                height: 'auto',
                alignSelf: 'center',
                padding: 0

            }}>
            {/* <PeriodToggle selectedPeriod={selectedPeriod} handlePeriodChange={this.handlePeriodChange} /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft:isMobile ?'5px':'25px', paddingTop:isMobile ?'5px':'10px',paddingRight:isMobile ?'5px':'25px'}}>
              <Toggle
              selectedValue={selectedPeriod}
              handleToggleChange={this.handlePeriodChange}
              options={[
                { key: 'daily', title: 'Daily' },
                { key: 'weekly', title: 'Weekly' },
                // Add more options as needed
              ]}
            />

          <Toggle
            selectedValue={selectedDsp}
            handleToggleChange={this.handleDspChange}
            options={[
              { key: 'all', title: 'Total' },
              { key: 'bydsp', title: 'By DSP' },
              // Add more options as needed
            ]}
          />
          </div>

            <LinePlotSimple
            key={[selectedPeriod, selectedDsp]} 
          sub_endpoint={info.chart_endpoint}
          // artistId={data.info.artistId}
          left_right={info.left_right}
          properties={info.properties}
          xKeyName={info.chart_xKeyName}
          title ={info.chart_title}
          xAxisTitle = {info.chart_xAxisTitle}
          yAxisTitle = {info.chart_yAxisTitle}
          yKeyNames={info.chart_yKeyNames}
          pct_change={info.chart_pct}
          hideLegend={info.hideLegend}

          />
          </div>

          </TabPanel>
          {/* <TabPanel value={activeTab} index={1}>
          <LinePlot endpoint = {endpoint} table_dir= {'us_passion_score_historical'} row = {row} id_column={id_column}></LinePlot>

          </TabPanel> */}
          {/* <TabPanel value={activeTab} index={2}>
          <ScatterPlot endpoint = {endpoint} row = {row}></ScatterPlot>

          </TabPanel> */}
        </div>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default ExpandedRow;
